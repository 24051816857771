import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

export default ({ page = "home" }) => {
  const data = useStaticQuery(graphql`
    query {
      arrowImage: file(relativePath: { eq: "nav-arrow.svg" }) {
        publicURL
      }
      logoImage: file(relativePath: { eq: "nav-logo.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <header>
      <nav className="nav navbar">
        <button
          id="menu_btn"
          className="nav-toggle collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#nav-links-list-wrap"
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon icon-menu"></span>
        </button>

        <Link className="nav-basics" to="/">
          <img className="nav-arrow" src={data.arrowImage.publicURL} alt="" />
          <img className="nav-logo" src={data.logoImage.publicURL} alt="" />
        </Link>
        <div
          className="nav-collapse collapse"
          id="nav-links-list-wrap"
          aria-label="Main menu"
        >
          <ul className="nav-links">
            <li className={page === "sanfrancisco" ? "active" : null}>
              <Link to="/sanfrancisco">ForwardJS: San Francisco</Link>
            </li>
            <li className={page === "ottawa" ? "active" : null}>
              <Link to="/ottawa">ForwardJS: Ottawa</Link>
            </li>
            <li className="flex-pull-right right-link">
              <a href="http://eepurl.com/buPLo1" target="blank">
                Sign up for our <strong>mailing list.</strong> (not spammy)
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}
