import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./layout.scss"

const Layout = ({ children, page="home" }) => {
  return (
    <>
      <Header page={page} />
      <main className="content-wrapper remodal-bg">{children}</main>
      <footer>
        <ul className="footer-links">
          <li>
            <a href="/codeofconduct">Code of Conduct</a>
          </li>
          <li>
            We're Tweeting:
            <a href="http://twitter.com/forwardjs">
              {" "}
              @<span className="link">forwardjs</span>
            </a>
          </li>
          <li>
            <a href="mailto:info@forwardjs.com">Support</a>
          </li>
          <li>
            <a href="mailto:info@forwardjs.com">Contact Us</a>
          </li>
        </ul>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
